import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Box, Container } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSnackbar } from 'notistack';
import { Text } from '../../components';
import { StandardLayout } from '../../layouts';
import { useStaticQueryImages } from '../../hooks/static-queries-hooks';
import { auth } from '../../firebase';

const title = `Dashboard`;
const subtitle = `Lass uns etwas zauber ✨`;
const contentText = [`TBD`];

export default function IndexPage() {
  const { profilePageImg } = useStaticQueryImages();
  const { enqueueSnackbar } = useSnackbar();

  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading === false && user == null && typeof window !== 'undefined') {
      enqueueSnackbar('Bitte melde dich an um fort zu fahren.', {
        variant: 'info',
      });
      navigate('/login');
    }
  }, [loading, user, enqueueSnackbar]);

  return (
    <StandardLayout
      imageProps={{
        imageData: profilePageImg,
        alt: 'Landingpage Image, showing a lavish meal.',
      }}
      headerProps={{
        title,
        subtitle,
      }}
    >
      <Box p={4}>
        <Container maxWidth="sm">
          {contentText.map((c) => (
            <Text.Content.Text
              key={c
                .substring(0, c.length > 10 ? 10 : c.length)
                .replace(' ', '')}
              text={c}
            />
          ))}
        </Container>
      </Box>
    </StandardLayout>
  );
}
