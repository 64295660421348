import React from 'react';
import { Router } from '@reach/router';
import { PrivateRoutes, DefaultPrivateRoute } from '../components';
import PrivateDashboard from '../private-pages/Dashboard';

export default function DashboardPage() {
  return (
    <Router basepath="/dashboard">
      <PrivateRoutes path="/" component={PrivateDashboard} />
      <DefaultPrivateRoute path="/" />
    </Router>
  );
}
